import { useState, useEffect } from "react";
import {
	Typography,
	Container,
	Button,
	Box,
	TextField,
	InputAdornment,
	Divider,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Snackbar,
	Alert,
} from "@mui/material";
import { getPipelineList } from "../services/documentService";
import SearchIcon from "@mui/icons-material/Search";
import ProjectTimeline from "../components/ProjectTimeline";
import ProjectCards from "../components/ProjectCards";
import ProjectStats from "../components/ProjectStats";
import ProjectFilters from "../components/ProjectFilters";
import NewProjectModal from "../components/NewProjectModal";
import { Pipeline } from "../components/ProjectStats";

const ProjectsOverview = () => {
	const [isNewProjectModalOpen, setIsNewProjectModalOpen] = useState(false);
	const [pipelineList, setPipelineList] = useState<Pipeline[]>([]);
	const [filteredPipelines, setFilteredPipelines] = useState<Pipeline[]>([]);
	const [activeFilters, setActiveFilters] = useState({ stage: "" });
	const [searchTerm, setSearchTerm] = useState("");
	const [isListModalOpen, setIsListModalOpen] = useState(false);
	const [snackbar, setSnackbar] = useState({
		open: false,
		message: "",
		severity: "info" as "info" | "success" | "warning" | "error",
	});

	useEffect(() => {
		handleGetPipelineList();
	}, []);

	useEffect(() => {
		filterPipelines();
	}, [searchTerm, activeFilters, pipelineList]);

	const handleNewProject = () => {
		setIsNewProjectModalOpen(true);
	};

	const handleGetPipelineList = async () => {
		try {
			const response = await getPipelineList(0);
			setPipelineList(response || []);
		} catch (error) {
			console.error("Failed to fetch pipeline list:", error);
			setPipelineList([]);
		}
	};

	const filterPipelines = () => {
		if (!Array.isArray(pipelineList)) {
			setFilteredPipelines([]);
			return;
		}

		let filtered = [...pipelineList];

		if (searchTerm) {
			filtered = filtered.filter(
				(pipeline) =>
					pipeline.codename &&
					pipeline.codename
						.toLowerCase()
						.includes(searchTerm.toLowerCase())
			);
		}

		Object.entries(activeFilters).forEach(([key, value]) => {
			if (value) {
				filtered = filtered.filter(
					(pipeline) =>
						pipeline[key as keyof typeof pipeline] === value
				);
			}
		});

		setFilteredPipelines(filtered);
	};

	const handleSnackbarClose = (
		_: React.SyntheticEvent | Event,
		reason?: string
	) => {
		if (reason === "clickaway") {
			return;
		}
		setSnackbar({ ...snackbar, open: false });
	};

	const showMessage = (
		message: string,
		severity: "info" | "success" | "warning" | "error"
	) => {
		setSnackbar({
			open: true,
			message,
			severity,
		});
	};

	const handleProjectUpdate = () => {
		showMessage("Project updated successfully", "success");
		handleGetPipelineList();
	};

	const handleProjectDelete = () => {
		showMessage("Project deleted successfully", "success");
		handleGetPipelineList();
	};

	return (
		<Container maxWidth="lg" sx={{ mt: 14, mb: 2 }}>
			<ProjectStats pipelineList={pipelineList} />
			<Box
				sx={{
					borderRadius: 4,
					overflow: "hidden",
					bgcolor: "rgba(255, 255, 255, 0.4)",
					backdropFilter: "blur(24px)",
					border: "1px solid",
					borderColor: "divider",
					padding: 2,
					boxShadow: `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`,
				}}
			>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						mb: 4,
					}}
				>
					<Typography variant="h5">Pipeline Overview</Typography>
					<Button variant="contained" onClick={handleNewProject}>
						New Pipeline
					</Button>
				</Box>
				<Divider sx={{ mx: -3 }} />
				<ProjectTimeline pipelineList={pipelineList} />
				<Divider sx={{ mx: -3 }} />
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						mt: 4,
						mb: 4,
					}}
				>
					<TextField
						placeholder="Search projects"
						variant="outlined"
						size="small"
						value={searchTerm}
						onChange={(e) => setSearchTerm(e.target.value)}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon />
								</InputAdornment>
							),
						}}
						sx={{ width: "300px" }}
					/>
					<ProjectFilters
						activeFilters={activeFilters}
						setActiveFilters={setActiveFilters}
					/>
				</Box>

				<ProjectCards
					pipelineList={filteredPipelines}
					searchTerm={searchTerm}
					activeFilters={activeFilters}
					onProjectUpdate={handleProjectUpdate}
					onProjectDelete={handleProjectDelete}
					showMessage={showMessage}
				/>

				<NewProjectModal
					open={isNewProjectModalOpen}
					onClose={() => setIsNewProjectModalOpen(false)}
					onCreate={(newPipeline, questionnaireData) => {
						console.log("New pipeline created:", newPipeline);
						console.log("Questionnaire data:", questionnaireData);
						setIsNewProjectModalOpen(false);
						showMessage(
							"New pipeline created successfully",
							"success"
						);
						handleGetPipelineList();
					}}
				/>

				<Dialog
					open={isListModalOpen}
					onClose={() => setIsListModalOpen(false)}
				>
					<DialogTitle>Pipeline List</DialogTitle>
					<DialogContent>
						{Array.isArray(pipelineList) &&
							pipelineList.map((pipeline, index) => (
								<Typography key={index}>
									{JSON.stringify(pipeline)}
								</Typography>
							))}
					</DialogContent>
					<DialogActions>
						<Button onClick={() => setIsListModalOpen(false)}>
							Close
						</Button>
					</DialogActions>
				</Dialog>
			</Box>
			<Snackbar
				open={snackbar.open}
				autoHideDuration={6000}
				onClose={(event, reason) => handleSnackbarClose(event, reason)}
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
			>
				<Alert
					onClose={handleSnackbarClose}
					severity={snackbar.severity}
					sx={{ width: "100%" }}
				>
					{snackbar.message}
				</Alert>
			</Snackbar>
		</Container>
	);
};

export default ProjectsOverview;
