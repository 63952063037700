import { useState } from 'react';
// material-ui
import { Snackbar, Alert } from '@mui/material';
import { createRoot } from 'react-dom/client';


interface MessageProps {
    content: string;
    duration?: number;
    type: 'success' | 'error' | 'warning' | 'info';
}

function Message(props: MessageProps) {
    const { content, duration, type } = { ...props };
    const [open, setOpen] = useState(true);

    const handleClose = () => {
        setOpen(false);
    };

    setTimeout(() => {  setOpen(false) }, duration);

    return (
        <Snackbar open={open} autoHideDuration={duration} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={handleClose}>
            <Alert severity={type} variant="standard">
                {content}
            </Alert>
        </Snackbar>
    );
}


const message = {
    dom: null as HTMLDivElement | null,
    success({ content, duration = 1500 }: { content: string; duration?: number }) {
        // 创建一个dom
        this.dom = document.createElement('div');
        // 定义组件，
        const JSXdom = <Message content={content} duration={duration} type="success"></Message>;
        // 渲染DOM
        createRoot(this.dom).render(JSXdom);
        // 置入到body节点下
        document.body.appendChild(this.dom);
    },
    error({ content, duration  = 1500 }: { content: string; duration?: number }) {
        this.dom = document.createElement('div');
        const JSXdom = <Message content={content} duration={duration} type="error"></Message>;
        createRoot(this.dom).render(JSXdom);
        document.body.appendChild(this.dom);
    },
    warning({ content, duration }: { content: string; duration?: number }) {
        this.dom = document.createElement('div');
        const JSXdom = <Message content={content} duration={duration} type="warning"></Message>;
        createRoot(this.dom).render(JSXdom);
        document.body.appendChild(this.dom);
    },
    info({ content, duration }: { content: string; duration?: number }) {
        this.dom = document.createElement('div');
        const JSXdom = <Message content={content} duration={duration} type="warning"></Message>;
        createRoot(this.dom).render(JSXdom);
        document.body.appendChild(this.dom);
    }
};

export default message;
