import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import {
	Typography,
	Box,
	TextField,
	List,
	ListItem,
	Checkbox,
	IconButton,
	Container,
	Tab,
	Tabs,
	MenuItem,
	Select,
	SelectChangeEvent,
	Chip,
	Divider,
	Snackbar,
	FormControl,
	InputLabel,
	LinearProgress,
	Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import InsightsIcon from "@mui/icons-material/Insights";
import CloseIcon from "@mui/icons-material/Close";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import CheckIcon from "@mui/icons-material/Check";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import MultiFileUpload from "../components/MultiFileUpload";
import {
	deleteFile,
	fetchDocuments,
	getPipelineList,
	updateFile,
	getModuleInsight,
} from "../services/documentService";
import useEctdData from "../components/useEctdData";
import { getRedictUrl } from "../assets/config";
import { useAuth0 } from "@auth0/auth0-react";
import message from "../components/Message";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
	props,
	ref
) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const STATUSES = [
	"All statuses",
	"Not Started",
	"On Hold",
	"Pending",
	"Cancelled",
	"Processing",
	"Stored",
];

const PERIODS = [
	"All",
	"Last Week",
	"Last Month",
	"Last 3 Months",
	"Last Year",
];

const DOCUMENT_TYPES = ["All", "PDF", "TXT", "DOC/DOCX", "OTHERS"];

interface Document {
	id: string;
	name: string;
	upload_date: string;
	processed_status: string;
	relevant_guidance_doc: string | null;
	summary: string | null;
	compliance: string | null;
	ectd_code: string | null;
	pipeline_id: string;
	size: number;
	type: string;
}

interface Project {
	id: string | undefined;
	codename: string;
	product_type: string;
	company: string;
	startDate: dayjs.Dayjs | null;
	documents: Document[];
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
	height: 10,
	borderRadius: 5,
	[`&.${LinearProgress}colorPrimary`]: {
		backgroundColor:
			theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
	},
	[`& .${LinearProgress}bar`]: {
		borderRadius: 5,
		backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
	},
}));

dayjs.extend(customParseFormat);

const ProjectDetail: React.FC = () => {
	const { projectId } = useParams<{ projectId: string }>();
	const [activeTab, setActiveTab] = useState("all_documents");
	const [filteredDocuments, setFilteredDocuments] = useState<Document[]>([]);
	const [searchTerm, setSearchTerm] = useState("");
	const [editingEctdCode, setEditingEctdCode] = useState<string | null>(null);
	const [selectedMainModule, setSelectedMainModule] = useState<string>("");
	const [selectedSubModule, setSelectedSubModule] = useState<string>("");
	const [status, setStatus] = useState("All statuses");
	const [period, setPeriod] = useState("All");
	const [documentType, setDocumentType] = useState("All");
	const [showModuleInsight, setShowModuleInsight] = useState(false);
	const [moduleInsightData, setModuleInsightData] = useState<unknown>(null);
	const [project, setProject] = useState<Project>({
		id: projectId,
		codename: "",
		product_type: "",
		company: "",
		startDate: null,
		documents: [],
	});
	const { moduleData } = useEctdData(projectId || "");
	const [snackbar, setSnackbar] = useState({
		open: false,
		message: "",
		severity: "info" as "info" | "success" | "warning" | "error",
	});

	const moduleInsightCache = new Map<string, unknown>();

	const handleModuleInsightClick = async () => {
		if (showModuleInsight) {
			setShowModuleInsight(false);
			return;
		}

		try {
			const moduleNum = activeTab.charAt(1);
			const cacheKey = `${projectId}-${moduleNum}`;

			if (moduleInsightCache.has(cacheKey)) {
				setModuleInsightData(moduleInsightCache.get(cacheKey));
				setShowModuleInsight(true);
				return;
			}

			const response = await getModuleInsight(
				projectId || "",
				Number(moduleNum)
			);

			if (!response.data) {
				throw new Error("No data received from the server");
			}

			moduleInsightCache.set(cacheKey, response.data);

			setModuleInsightData(response.data);
			setShowModuleInsight(true);
		} catch (error) {
			console.error("Error fetching module insight:", error);

			setModuleInsightData(null);
			setShowModuleInsight(false);
		}
	};

	const renderActionButton = () => {
		if (activeTab.startsWith("m")) {
			return (
				<Button
					variant="outlined"
					startIcon={
						showModuleInsight ? (
							<SwapHorizIcon />
						) : (
							<AnalyticsIcon />
						)
					}
					onClick={handleModuleInsightClick}
					sx={{
						borderRadius: 2,
						textTransform: "none",
						fontWeight: "bold",
						color: "primary.main",
						borderColor: "primary.main",
						"&:hover": {
							backgroundColor: "primary.light",
							borderColor: "primary.main",
						},
						whiteSpace: "nowrap",
					}}
				>
					{showModuleInsight
						? "Switch to Module View"
						: "Module Insight"}
				</Button>
			);
		} else if (activeTab === "all_documents") {
			return (
				<Button
					variant="outlined"
					startIcon={<InsightsIcon />}
					onClick={() => {
						/* Handle Pipeline Overview click */
					}}
					sx={{
						borderRadius: 2,
						textTransform: "none",
						fontWeight: "bold",
						color: "primary.main",
						borderColor: "primary.main",
						"&:hover": {
							backgroundColor: "primary.light",
							borderColor: "primary.main",
						},
						whiteSpace: "nowrap",
					}}
				>
					Pipeline Overview
				</Button>
			);
		}
		return null;
	};

	useEffect(() => {
		const fetchDocs = async () => {
			try {
				if (projectId) {
					const docs = await fetchDocuments(projectId);
					setFilteredDocuments(docs);
				}
			} catch (error) {
				console.error("Error fetching documents:", error);
			}
		};

		if (projectId) {
			fetchDocs();
		}
	}, [projectId]);

	const renderMainModuleOptions = () => {
		return Object.keys(moduleData).map((moduleKey) => (
			<MenuItem key={moduleKey} value={moduleKey}>
				{moduleKey}
			</MenuItem>
		));
	};

	const renderSubModuleOptions = (mainModule: string) => {
		if (!mainModule || !moduleData[mainModule]) return null;

		return moduleData[mainModule].map((subModule) => (
			<MenuItem key={subModule.code} value={subModule.code}>
				{subModule.code} - {subModule.name}
				{subModule.required && (
					<span
					// style={{ color: "red" }}
					>
						{" "}
						*
					</span>
				)}
			</MenuItem>
		));
	};

	const handleMainModuleChange = (event: SelectChangeEvent<string>) => {
		setSelectedMainModule(event.target.value as string);
		setSelectedSubModule("");
	};

	const handleSubModuleChange = (event: SelectChangeEvent<string>) => {
		setSelectedSubModule(event.target.value as string);
	};

	const handleEctdCodeEdit = (docId: string, currentEctdCode: string) => {
		setEditingEctdCode(docId);
		const [mainModule] = currentEctdCode.split(".");
		setSelectedMainModule(`M${mainModule}`);
		setSelectedSubModule(currentEctdCode);
	};

	const handleEctdCodeSave = async (docId: string) => {
		try {
			await updateFile(docId, { ectd_code: selectedSubModule });
			setSnackbar({
				open: true,
				message: "eCTD code updated successfully",
				severity: "success",
			});
			const updatedDocuments = await fetchDocuments(projectId || "");
			setProject((prev) => ({
				...prev,
				documents: updatedDocuments || [],
			}));
		} catch (error) {
			console.error("Error updating eCTD code:", error);
			setSnackbar({
				open: true,
				message: "Error updating eCTD code. Please try again.",
				severity: "error",
			});
		} finally {
			setEditingEctdCode(null);
			setSelectedMainModule("");
			setSelectedSubModule("");
		}
	};

	const handleEctdCodeCancel = () => {
		setEditingEctdCode(null);
		setSelectedMainModule("");
		setSelectedSubModule("");
	};

	const getModuleStats = (moduleNumber: string) => {
		const moduleKey = `M${moduleNumber}`;
		const moduleDocuments = filteredDocuments.filter(
			(doc) =>
				doc.ectd_code && doc.ectd_code.startsWith(moduleNumber + ".")
		);

		const totalFiles = moduleData[moduleKey]
			? moduleData[moduleKey].length
			: 0;
		const uploadedFiles = moduleDocuments.length;

		const totalRequiredFiles = moduleData[moduleKey]
			? moduleData[moduleKey].filter((item) => item.required).length
			: 0;
		const uploadedRequiredFiles = moduleDocuments.filter((doc) => {
			const isRequired = moduleData[moduleKey].some((item) => {
				const itemCodeParts = item.code.split("/");
				const itemCode = itemCodeParts[itemCodeParts.length - 1];
				return item.required && itemCode === doc.ectd_code;
			});
			return isRequired;
		}).length;

		return {
			totalFiles,
			uploadedFiles,
			totalRequiredFiles,
			uploadedRequiredFiles,
		};
	};

	const renderModuleStats = (moduleNumber: string) => {
		const stats = getModuleStats(moduleNumber);
		const filesPercentage = (stats.uploadedFiles / stats.totalFiles) * 100;
		const requiredFilesPercentage =
			(stats.uploadedRequiredFiles / stats.totalRequiredFiles) * 100;

		return (
			<Box sx={{ mb: 2, display: "flex", alignItems: "center", gap: 2 }}>
				<Box sx={{ flexGrow: 1 }}>
					<Typography variant="body2" sx={{ mb: 0.5 }}>
						Files: {stats.uploadedFiles}/{stats.totalFiles} (
						{filesPercentage.toFixed(0)}%)
					</Typography>
					<BorderLinearProgress
						variant="determinate"
						value={filesPercentage}
					/>
				</Box>
				<Box sx={{ flexGrow: 1 }}>
					<Typography variant="body2" sx={{ mb: 0.5 }}>
						Required: {stats.uploadedRequiredFiles}/
						{stats.totalRequiredFiles} (
						{requiredFilesPercentage.toFixed(0)}%)
					</Typography>
					<BorderLinearProgress
						variant="determinate"
						value={requiredFilesPercentage}
					/>
				</Box>
			</Box>
		);
	};

	useEffect(() => {
		const loadProjectDetails = async () => {
			try {
				if (!projectId) {
					throw new Error("Project ID is undefined");
				}
				const response = await getPipelineList(Number(projectId));

				if (!Array.isArray(response) || response.length === 0) {
					throw new Error(
						"Invalid or empty response from getPipelineList"
					);
				}

				let projectData;
				if (projectId === "0-0") {
					projectData = response[0];
				} else {
					projectData = response.find(
						(project) => project.id === projectId
					);
				}

				if (!projectData) {
					throw new Error("Project not found");
				}

				setProject((prevProject) => ({
					...prevProject,
					codename: projectData.codename || "",
					product_type: projectData.product_type || "",
					company: projectData.company || "",
					startDate: projectData.start_date
						? dayjs(projectData.start_date)
						: null,
				}));
			} catch (error) {
				console.error("Error in loadProjectDetails:", error);
				setSnackbar({
					open: true,
					message:
						error instanceof Error
							? error.message
							: "An unexpected error occurred. Please try again.",
					severity: "error",
				});
			}
		};

		const loadDocuments = async () => {
			try {
				const fetchedDocuments = await fetchDocuments(projectId || "");

				if (!fetchedDocuments || fetchedDocuments.length === 0) {
					console.log("No documents fetched or empty array returned");
				}

				setProject((prev) => {
					const updatedProject = {
						...prev,
						documents: fetchedDocuments || [],
					};
					return updatedProject;
				});

				setFilteredDocuments(fetchedDocuments || []);
			} catch (error) {
				setSnackbar({
					open: true,
					message: "Error fetching documents. Please try again.",
					severity: "error",
				});
			}
		};

		loadProjectDetails();
		loadDocuments();
	}, [projectId]);

	useEffect(() => {
		const filterDocuments = () => {
			let filtered = project.documents;

			if (searchTerm) {
				filtered = filtered.filter(
					(doc) =>
						doc.name
							.toLowerCase()
							.includes(searchTerm.toLowerCase()) ||
						(doc.ectd_code &&
							doc.ectd_code
								.toLowerCase()
								.includes(searchTerm.toLowerCase()))
				);
			}

			setFilteredDocuments(filtered);
		};

		filterDocuments();
	}, [searchTerm, project.documents]);

	const handleUploadSuccess = () => {
		fetchDocuments(projectId || "")
			.then((fetchedDocuments) => {
				setProject((prev) => ({
					...prev,
					documents: fetchedDocuments || [],
				}));
				setFilteredDocuments(fetchedDocuments);
			})
			.catch(() => {
				setSnackbar({
					open: true,
					message: "Error fetching documents. Please try again.",
					severity: "error",
				});
			});
	};

	const handleUploadError = (error: unknown) => {
		console.error("Upload error:", error);
		setSnackbar({
			open: true,
			message: `Error uploading files: ${error}`,
			severity: "error",
		});
	};

	const handleCloseSnackbar = () => {
		setSnackbar({ ...snackbar, open: false });
	};

	const commonSelectStyles = {
		minWidth: 200,
		"& .MuiOutlinedInput-root": {
			borderRadius: 2,
			bgcolor: "background.paper",
			boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
			"& fieldset": {
				borderColor: "rgba(0, 0, 0, 0.23)",
			},
			"&:hover fieldset": {
				borderColor: "rgba(0, 0, 0, 0.87)",
			},
			"&.Mui-focused fieldset": {
				borderColor: "primary.main",
			},
		},
	};

	const renderFilterDropdown = (
		value: string,
		setValue: React.Dispatch<React.SetStateAction<string>>,
		options: string[],
		label: string
	) => (
		<FormControl sx={commonSelectStyles}>
			<InputLabel>{label}</InputLabel>
			<Select
				value={value}
				onChange={(e) => {
					setValue(e.target.value as string);
				}}
				label={label}
			>
				{options.map((option) => (
					<MenuItem key={option} value={option}>
						{option}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);

	const filterDocuments = useCallback(() => {
		if (!project.documents || project.documents.length === 0) {
			setFilteredDocuments([]);
			return;
		}

		let filtered = project.documents;

		if (searchTerm) {
			filtered = filtered.filter(
				(doc) =>
					doc.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
					(doc.ectd_code &&
						doc.ectd_code
							.toLowerCase()
							.includes(searchTerm.toLowerCase()))
			);
		}

		if (status !== "All statuses") {
			filtered = filtered.filter(
				(doc) =>
					doc.processed_status.toLowerCase() === status.toLowerCase()
			);
		}

		if (period !== "All") {
			const now = dayjs();

			filtered = filtered.filter((doc) => {
				const formats = [
					"MMMM D, YYYY [at] h:mm A",
					"YYYY-MM-DD HH:mm:ss",
					"MM/DD/YYYY HH:mm:ss",
					"YYYY-MM-DDTHH:mm:ss.SSSZ",
				];

				let validUploadDate: dayjs.Dayjs | null = null;
				for (const format of formats) {
					const parsedDate = dayjs(doc.upload_date, format);
					if (parsedDate.isValid()) {
						validUploadDate = parsedDate;
						break;
					}
				}

				if (!validUploadDate || !validUploadDate.isValid()) {
					console.error(
						`Invalid date format for document: ${doc.name}, Date: ${doc.upload_date}`
					);
					return false;
				}

				let isWithinPeriod = false;
				switch (period) {
					case "Last Week": {
						const sevenDaysAgo = now
							.subtract(7, "day")
							.startOf("day");
						isWithinPeriod =
							validUploadDate.isAfter(sevenDaysAgo) ||
							validUploadDate.isSame(sevenDaysAgo, "day");
						break;
					}
					case "Last Month": {
						isWithinPeriod = now.diff(validUploadDate, "month") < 1;
						break;
					}
					case "Last 3 Months": {
						isWithinPeriod = now.diff(validUploadDate, "month") < 3;
						break;
					}
					case "Last Year": {
						isWithinPeriod = now.diff(validUploadDate, "year") < 1;
						break;
					}
					default:
						isWithinPeriod = true;
				}
				return isWithinPeriod;
			});
		}

		if (documentType !== "All") {
			filtered = filtered.filter((doc) => {
				const extension = doc.name.split(".").pop()?.toUpperCase();
				switch (documentType) {
					case "PDF":
						return extension === "PDF";
					case "TXT":
						return extension === "TXT";
					case "DOC/DOCX":
						return extension === "DOC" || extension === "DOCX";
					case "OTHERS":
						return !["PDF", "TXT", "DOC", "DOCX"].includes(
							extension || ""
						);
					default:
						return true;
				}
			});
		}

		setFilteredDocuments(filtered);
	}, [project.documents, searchTerm, status, period, documentType]);

	useEffect(() => {
		filterDocuments();
	}, [filterDocuments]);

	const formatDate = (dateString: string): string => {
		const date = new Date(dateString);
		if (isNaN(date.getTime())) {
			const parts = dateString.split(" at ");
			if (parts.length === 2) {
				const [monthDay, year] = parts[0].split(", ");
				const [month, day] = monthDay.split(" ");
				const monthIndex =
					new Date(Date.parse(month + " 1, 2012")).getMonth() + 1;
				return `${monthIndex
					.toString()
					.padStart(2, "0")}/${day.padStart(2, "0")}/${year}`;
			}
			return dateString;
		}
		return date.toLocaleDateString("en-US", {
			month: "2-digit",
			day: "2-digit",
			year: "numeric",
		});
	};

	const formatSize = (size: number): string => {
		return `${size.toFixed(2)}MB`;
	};

	const processTags = (tagData: string | null): string[] => {
		if (!tagData) return [];
		try {
			const parsedData = JSON.parse(tagData);
			if (Array.isArray(parsedData)) {
				return parsedData.map((ectd_code) => ectd_code.trim());
			}
		} catch (error) {
			// console.log('Tag data is not in JSON format, trying other parsing methods');
		}

		if (tagData.includes("\\\\,")) {
			return tagData
				.split("\\\\,")
				.map((ectd_code) => ectd_code.trim().replace(/\\+$/, ""));
		}

		if (tagData.includes(",")) {
			return tagData.split(",").map((ectd_code) => ectd_code.trim());
		}

		return [tagData.trim()];
	};

	const handleDeleteFile = async (docId: string) => {
		try {
			await deleteFile(docId);
			setSnackbar({
				open: true,
				message: "File deleted successfully",
				severity: "success",
			});
			const updatedDocuments = await fetchDocuments(projectId || "");
			setProject((prev) => ({
				...prev,
				documents: updatedDocuments || [],
			}));
		} catch (error) {
			console.error("Error deleting file:", error);
			setSnackbar({
				open: true,
				message: "Error deleting file. Please try again.",
				severity: "error",
			});
		}
	};

	const renderModuleInsightContent = () => {
		if (!moduleInsightData) return null;

		const processContent = (content: string) => {
			const sections = content.split(/(?=##)/);

			return sections.map((section, index) => {
				const lines = section.trim().split("\n");
				const content = lines.slice(1).join("\n");

				return (
					<Box key={index} sx={{ mb: 4 }}>
						<Box sx={{ pl: 2 }}>
							{content.split("\n").map((line, lineIndex) => {
								// Process bold text
								const parts = line.split(/(\*\*.*?\*\*)/);

								return (
									<Typography
										key={lineIndex}
										variant="body1"
										sx={{
											mb: 1,
											lineHeight: 2,
										}}
									>
										{parts.map((part, partIndex) => {
											if (
												part.startsWith("**") &&
												part.endsWith("**")
											) {
												return (
													<Box
														key={partIndex}
														component="span"
														sx={{
															fontWeight: 600,
															color: "text.primary",
														}}
													>
														{part.slice(2, -2)}
													</Box>
												);
											}
											return part;
										})}
									</Typography>
								);
							})}
						</Box>
					</Box>
				);
			});
		};

		return (
			<Box>
				<Typography
					variant="h5"
					sx={{
						mb: 3,
						fontWeight: 700,
						color: "text.primary",
						borderBottom: "2px solid",
						borderColor: "primary.main",
						pb: 1,
					}}
				>
					Module Insight Analysis
				</Typography>

				{typeof moduleInsightData === "string"
					? processContent(moduleInsightData)
					: processContent(
							JSON.stringify(moduleInsightData, null, 2)
					  )}
			</Box>
		);
	};

	const renderDocumentList = () => {
		if (showModuleInsight && moduleInsightData) {
			return renderModuleInsightContent();
		}

		const processedDocuments = filteredDocuments.map((doc) => ({
			...doc,
			ectd_code: doc.ectd_code || "others",
		}));

		if (activeTab === "all_documents") {
			return renderFilteredDocuments(processedDocuments, isAuthenticated);
		} else if (activeTab === "others") {
			const otherDocuments = processedDocuments.filter(
				(doc) =>
					doc.ectd_code === "others" ||
					!["1.", "2.", "3.", "4.", "5."].some((prefix) =>
						doc.ectd_code.startsWith(prefix)
					)
			);
			return renderFilteredDocuments(otherDocuments, isAuthenticated);
		} else {
			const moduleNumber = activeTab.charAt(1);
			const moduleKey = `M${moduleNumber}`;

			if (moduleData && moduleData[moduleKey]) {
				const moduleDocuments = processedDocuments.filter((doc) =>
					doc.ectd_code.startsWith(moduleNumber + ".")
				);

				return (
					<>
						{renderModuleStats(moduleNumber)}
						<List>
							{moduleData[moduleKey].map(
								({ code, name, required }, index) => (
									<React.Fragment key={index}>
										<ListItem
											sx={{
												borderBottom: "1px solid",
												borderColor: "divider",
												py: 2,
												alignItems: "center",
											}}
										>
											<Box
												sx={{
													flexGrow: 1,
													display: "flex",
													alignItems: "center",
													gap: 2,
												}}
											>
												<Typography
													variant="customH5"
													sx={{
														minWidth: 800,
														maxWidth: 800,
														overflow: "hidden",
														textOverflow:
															"ellipsis",
														whiteSpace: "nowrap",
													}}
												>
													{code} {name}{" "}
													{required && <span>*</span>}
												</Typography>
											</Box>
										</ListItem>
										{renderFilteredDocuments(
											moduleDocuments.filter((doc) =>
												doc.ectd_code.startsWith(code)
											),
											isAuthenticated
										)}
									</React.Fragment>
								)
							)}
						</List>
					</>
				);
			} else {
				return <Typography>No documents found.</Typography>;
			}
		}
	};

	const { isAuthenticated } = useAuth0();
	const renderFilteredDocuments = (
		documents: Document[],
		isUserAuthenticated: boolean
	) => {
		return (
			<List>
				{documents.length > 0 ? (
					documents.map((doc: Document) => (
						<ListItem
							key={doc.id}
							sx={{
								borderBottom: "1px solid",
								borderColor: "divider",
								py: 2,
								alignItems: "center",
							}}
						>
							<Checkbox />
							<Box
								sx={{
									flexGrow: 1,
									display: "flex",
									alignItems: "center",
									gap: 2,
								}}
							>
								<Typography
									variant="body1"
									component={Link}
									onClick={() => {
										if (!isUserAuthenticated) {
											message.error({
												content:
													"Please login to view projects",
											});
											return;
										}
										// navigate(getRedictUrl(`/document/${doc.id}`))
									}}
									to={
										!isUserAuthenticated
											? "#"
											: getRedictUrl(
													`/document/${doc.id}`
											  )
									}
									state={{
										document: doc,
										ectdCode: doc.ectd_code,
									}}
									sx={{
										minWidth: 300,
										maxWidth: 300,
										overflow: "hidden",
										textOverflow: "ellipsis",
										whiteSpace: "nowrap",
										textDecoration: "none",
										color: "inherit",
									}}
								>
									{doc.name}
								</Typography>
								<Typography
									variant="body2"
									color="text.secondary"
									sx={{ minWidth: 100 }}
								>
									{formatDate(doc.upload_date)}
								</Typography>
								<Typography
									variant="body2"
									color="text.secondary"
									sx={{ minWidth: 100 }}
								>
									{doc.processed_status}
								</Typography>
								<Box
									sx={{
										display: "flex",
										gap: 1,
										flexGrow: 1,
										flexWrap: "wrap",
									}}
								>
									{processTags(doc.ectd_code).map(
										(ectd_code, index) =>
											editingEctdCode === doc.id ? (
												<Box
													key={index}
													sx={{
														display: "flex",
														gap: 1,
													}}
												>
													<FormControl
														size="small"
														sx={{ minWidth: 120 }}
													>
														<InputLabel>
															Main Module
														</InputLabel>
														<Select
															value={
																selectedMainModule
															}
															onChange={
																handleMainModuleChange
															}
															label="Main Module"
														>
															{renderMainModuleOptions()}
														</Select>
													</FormControl>
													<FormControl
														size="small"
														sx={{ minWidth: 200 }}
													>
														<InputLabel>
															Sub Module
														</InputLabel>
														<Select
															value={
																selectedSubModule
															}
															onChange={
																handleSubModuleChange
															}
															label="Sub Module"
															disabled={
																!selectedMainModule
															}
														>
															{renderSubModuleOptions(
																selectedMainModule
															)}
														</Select>
													</FormControl>
													<IconButton
														size="small"
														onClick={() =>
															handleEctdCodeSave(
																doc.id
															)
														}
													>
														<CheckIcon fontSize="small" />
													</IconButton>
													<IconButton
														size="small"
														onClick={
															handleEctdCodeCancel
														}
													>
														<CloseIcon fontSize="small" />
													</IconButton>
												</Box>
											) : (
												<Chip
													key={index}
													label={<>{ectd_code}</>}
													size="small"
													onClick={() =>
														handleEctdCodeEdit(
															doc.id,
															ectd_code
														)
													}
												/>
											)
									)}
								</Box>
								<Typography
									variant="body2"
									color="text.secondary"
									sx={{ minWidth: 80 }}
								>
									{formatSize(doc.size)}
								</Typography>
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										whiteSpace: "nowrap",
									}}
								>
									<IconButton
										component={Link}
										to={`/document/${doc.id}`}
										state={{
											document: doc,
											ectdCode: doc.ectd_code,
										}}
										size="small"
									>
										<InsightsIcon fontSize="small" />
									</IconButton>
									<IconButton
										size="small"
										onClick={() => handleDeleteFile(doc.id)}
									>
										<DeleteIcon fontSize="small" />
									</IconButton>
								</Box>
							</Box>
						</ListItem>
					))
				) : (
					<ListItem>
						<Typography>No documents found.</Typography>
					</ListItem>
				)}
			</List>
		);
	};

	return (
		<Container maxWidth="lg" sx={{ mt: 14, mb: 2 }}>
			<Box
				sx={{
					borderRadius: 4,
					overflow: "hidden",
					bgcolor: "rgba(255, 255, 255, 0.4)",
					backdropFilter: "blur(24px)",
					border: "1px solid",
					borderColor: "divider",
					boxShadow: `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`,
				}}
			>
				<Box
					sx={{
						p: 3,
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						mb: 2,
						gap: 2,
					}}
				>
					<Box>
						<Typography
							variant="h5"
							sx={{ textTransform: "uppercase" }}
						>
							{project.codename}
						</Typography>
						<Typography variant="body1">
							{project.product_type}
						</Typography>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "flex-end",
						}}
					>
						<Typography variant="body1">
							Company: {project.company}
						</Typography>
						<Typography variant="body1">
							Start Date:{" "}
							{project.startDate?.format("MM/DD/YYYY")}
						</Typography>
					</Box>
				</Box>
				<Divider />
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						px: 2,
					}}
				>
					<Tabs
						value={activeTab}
						onChange={(_, newValue) => {
							setActiveTab(newValue);
							setShowModuleInsight(false);
							setModuleInsightData(null);
						}}
						aria-label="document tabs"
					>
						<Tab label="All documents" value="all_documents" />
						<Tab label="Module 1" value="m1" />
						<Tab label="Module 2" value="m2" />
						<Tab label="Module 3" value="m3" />
						<Tab label="Module 4" value="m4" />
						<Tab label="Module 5" value="m5" />
						<Tab label="Others" value="others" />
					</Tabs>
					<Box sx={{ display: "flex", gap: 2 }}>
						{renderActionButton()}
					</Box>
				</Box>
				<Box sx={{ p: 3 }}>
					{activeTab === "all_documents" && !showModuleInsight && (
						<>
							<Box sx={{ display: "flex", mb: 2, gap: 2 }}>
								<TextField
									placeholder="Search documents"
									variant="outlined"
									label="Search Documents"
									value={searchTerm}
									onChange={(e) =>
										setSearchTerm(e.target.value)
									}
									size="small"
									sx={{
										flexGrow: 1,
										"& .MuiOutlinedInput-root": {
											borderRadius: 2,
											bgcolor: "background.paper",
											boxShadow:
												"0 2px 4px rgba(0,0,0,0.1)",
											"& fieldset": {
												borderColor:
													"rgba(0, 0, 0, 0.23)",
											},
											"&:hover fieldset": {
												borderColor:
													"rgba(0, 0, 0, 0.87)",
											},
											"&.Mui-focused fieldset": {
												borderColor: "primary.main",
											},
										},
									}}
									InputProps={{
										endAdornment: (
											<SearchIcon color="action" />
										),
									}}
								/>
								{renderFilterDropdown(
									status,
									setStatus,
									STATUSES,
									"Status"
								)}
								{renderFilterDropdown(
									period,
									setPeriod,
									PERIODS,
									"Period"
								)}
								{renderFilterDropdown(
									documentType,
									setDocumentType,
									DOCUMENT_TYPES,
									"Document type"
								)}
							</Box>

							<MultiFileUpload
								projectId={projectId || ""}
								onUploadSuccess={handleUploadSuccess}
								onUploadError={handleUploadError}
								moduleData={moduleData}
							/>
						</>
					)}
					{renderDocumentList()}
				</Box>
			</Box>
			<Snackbar
				open={snackbar.open}
				autoHideDuration={6000}
				onClose={handleCloseSnackbar}
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
			>
				<Alert
					onClose={handleCloseSnackbar}
					severity={snackbar.severity}
					sx={{ width: "100%" }}
				>
					{snackbar.message}
				</Alert>
			</Snackbar>
		</Container>
	);
};

export default ProjectDetail;
