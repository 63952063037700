import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, MenuItem } from '@mui/material';
import { updatePipeline, PipelineData } from '../services/documentService';

interface EditPipelineModalProps {
  open: boolean;
  onClose: () => void;
  pipeline: PipelineData | null;
  onUpdate: () => void;
}

const EditPipelineModal: React.FC<EditPipelineModalProps> = ({ open, onClose, pipeline, onUpdate }) => {
  const [updatedPipeline, setUpdatedPipeline] = useState<PipelineData | null>(pipeline);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    if (pipeline) {
      setUpdatedPipeline(pipeline);
    }
  }, [pipeline]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (updatedPipeline) {
      setUpdatedPipeline({ ...updatedPipeline, [e.target.name]: e.target.value });
    }
  };

  const handleUpdate = async () => {
    if (updatedPipeline) {
      setIsUpdating(true);
      try {
        await updatePipeline(updatedPipeline.id, updatedPipeline);
        onUpdate();
        onClose();
        // setTimeout(() => {
        //   window.location.reload();
        // }, 1000);
      } catch (error) {
        console.error('Failed to update pipeline:', error);
      } finally {
        setIsUpdating(false);
      }
    }
  };
  
  if (!updatedPipeline) {
    return null;
  };

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Edit Pipeline</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            name="active"
            label="Active"
            required={true}
            select
            fullWidth
            variant="outlined"
            value={updatedPipeline.active ?? 1}
            onChange={handleInputChange}
            InputProps={{ style: { height: 35 } }}
          >
            <MenuItem value={0}>Inactive</MenuItem>
            <MenuItem value={1}>Active</MenuItem>
          </TextField>
          <TextField
            margin="dense"
            name="active_notes"
            label="Active Notes"
            required={true}
            fullWidth
            variant="outlined"
            value={updatedPipeline.active_notes || ''}
            onChange={handleInputChange}
            InputProps={{ style: { height: 35 } }}
            placeholder="Enter active notes"
          />
          <TextField
            margin="dense"
            name="codename"
            label="Codename"
            required={true}
            fullWidth
            variant="outlined"
            value={updatedPipeline.codename || ''}
            onChange={handleInputChange}
            InputProps={{ style: { height: 35 } }}
            placeholder="Enter codename"
          />
          <TextField
            margin="dense"
            name="company"
            label="Company"
            required={true}
            fullWidth
            variant="outlined"
            value={updatedPipeline.company || ''}
            onChange={handleInputChange}
            InputProps={{ style: { height: 35 } }}
            placeholder="Enter company name"
          />
          <TextField
            margin="dense"
            name="product_type"
            label="Product Type"
            required={true}
            fullWidth
            variant="outlined"
            value={updatedPipeline.product_type || ''}
            onChange={handleInputChange}
            InputProps={{ style: { height: 35 } }}
            placeholder="Enter product type"
          />
          <TextField
            margin="dense"
            name="fda_history"
            label="FDA History"
            required={true}
            fullWidth
            variant="outlined"
            value={updatedPipeline?.fda_history || ''}
            onChange={handleInputChange}
            InputProps={{ style: { height: 35 } }}
            placeholder="Enter FDA history"
          />
          <TextField
            margin="dense"
            name="start_date"
            label="Start Date"
            required={true}
            type="date"
            fullWidth
            variant="outlined"
            value={updatedPipeline.start_date || ''}
            onChange={handleInputChange}
            InputLabelProps={{
              shrink: true,
              style: { height: 35 },
            }}
          />
          <TextField
            margin="dense"
            name="compliance"
            label="Compliance"
            required={false}
            fullWidth
            variant="outlined"
            value={updatedPipeline?.compliance || ''}
            onChange={handleInputChange}
            InputProps={{ style: { height: 35 } }}
            placeholder="Enter compliance details"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleUpdate} variant="contained" disabled={isUpdating}>
            {isUpdating ? 'Updating...' : 'Update'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditPipelineModal;