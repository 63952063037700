// import * as React from 'react';
// import { alpha } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
// import dashboard from "../assets/dashboard_sample.png";

export default function Hero() {
	return (
		<Box
			id="hero"
			sx={{
				width: "100%",
				height: { xs: 200, sm: 1200 },
				backgroundImage: "linear-gradient(180deg, #CEE5FD, #FFF)",
				backgroundSize: "100% 40%",
				backgroundRepeat: "no-repeat",
			}}
		>
			<Container
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					pt: { xs: 14, sm: 20 },
					pb: { xs: 8, sm: 12 },
				}}
			>
				<Stack
					spacing={2}
					useFlexGap
					sx={{ width: { xs: "100%", sm: "70%" } }}
				>
					<Typography
						variant="h1"
						sx={{
							display: "flex",
							flexDirection: { xs: "column", md: "row" },
							alignSelf: "center",
							textAlign: "center",
							fontSize: "clamp(4.5rem, 10vw, 4rem)",
						}}
					>
						Our latest&nbsp;
						<Typography
							component="span"
							variant="h1"
							sx={{
								fontSize: "clamp(4.5rem, 10vw, 4rem)",
								color: "primary.main",
							}}
						>
							products
						</Typography>
					</Typography>
					<Typography
						textAlign="center"
						color="text.secondary"
						sx={{
							alignSelf: "center",
							width: { sm: "100%", md: "80%" },
						}}
					>
						Explore our cutting-edge dashboard, delivering
						high-quality solutions tailored to your needs. Elevate
						your experience with top-tier features and services.
					</Typography>
					<Stack
						direction={{ xs: "column", sm: "row" }}
						alignSelf="center"
						spacing={1}
						useFlexGap
						sx={{
							pt: 2,
							width: { xs: "100%", sm: "auto" },
						}}
					>
						<TextField
							id="outlined-basic"
							hiddenLabel
							size="small"
							variant="outlined"
							aria-label="Enter your email address"
							placeholder="Your email address"
							inputProps={{
								autoComplete: "off",
								"aria-label": "Enter your email address",
							}}
						/>
						<Button variant="contained" color="primary">
							Start now
						</Button>
					</Stack>
					<Typography
						variant="caption"
						textAlign="center"
						sx={{ opacity: 0.8 }}
					>
						By clicking "Start now" you agree to our
						<Link href="#" color="primary">
							Terms & Conditions
						</Link>
						.
					</Typography>
				</Stack>
				{/* <Box
					id="image"
					sx={{
						mt: { xs: 8, sm: 10 },
						alignSelf: "center",
						height: { xs: 200, sm: 600 },
						width: "100%",
						backgroundImage:`url(${dashboard})`,
						backgroundSize: "cover",
						borderRadius: "20px",
						outline: "1px solid",
						outlineColor: alpha("#BFCCD9", 0.5),
						boxShadow: `0 0 12px 8px ${alpha("#9CCCFC", 0.2)}`,
					}}
				></Box> */}
			</Container>
		</Box>
	);
}
