import { FormControl, InputLabel, Select, MenuItem, Box, SelectChangeEvent } from '@mui/material';

const stages = ['All', 'Pre-IND', 'IND Application', 'Phase I', 'Phase II', 'Phase III', 'NDA'];

interface ProjectFiltersProps {
  activeFilters: {
    stage: string;
  };
  setActiveFilters: (filters: { stage: string }) => void;
}

const ProjectFilters: React.FC<ProjectFiltersProps> = ({ activeFilters, setActiveFilters }) => {
  const handleStageChange = (event: SelectChangeEvent<string>) => {
    setActiveFilters({ ...activeFilters, stage: event.target.value });
  };

  return (
    <Box>
      <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
        <InputLabel>Stage</InputLabel>
        <Select
          value={activeFilters.stage || 'All'}
          onChange={handleStageChange}
          label="Stage"
        >
          {stages.map((stage) => (
            <MenuItem key={stage} value={stage}>{stage}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default ProjectFilters;