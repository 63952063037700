import { Divider } from "@mui/material";
import Hero from "../components/Hero";
// import Features from "../components/Features";
import Highlights from "../components/Highlights";
// import Pricing from '../components/Pricing';
import FAQ from "../components/FAQ";

const Home = () => {
	return (
		<div>
			<Hero />
			<Divider />
			{/* <Features /> */}
			<Divider />
			<Highlights />
			<Divider />
			{/* <Pricing /> */}
			<Divider />
			<FAQ />
		</div>
	);
};

export default Home;
