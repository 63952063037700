import React, { useState } from "react";
import { Container, TextField, Button, Typography, Box } from "@mui/material";

const Account: React.FC = () => {
	const [companyName, setCompanyName] = useState("Default Company");
	const [address, setAddress] = useState("Default Address");
	const [email, setEmail] = useState("default@example.com");
	const [phoneNumber, setPhoneNumber] = useState("Default Phone Number");
	const [contactPerson, setContactPerson] = useState(
		"Default Contact Person"
	);

	const handleUpdate = () => {
		// 更新逻辑
		console.log("Updated information:", {
			companyName,
			address,
			email,
			phoneNumber,
			contactPerson,
		});
	};

	return (
		<Container maxWidth="sm">
			<Box sx={{ mt: 4 }}>
				<Typography
					component="h1"
					variant="h5"
					sx={{
						marginTop: 16,
						marginBottom: 6,
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					Account Information
				</Typography>
				<TextField
					fullWidth
					label="Company Name"
					value={companyName}
					onChange={(e) => setCompanyName(e.target.value)}
					margin="normal"
				/>
				<TextField
					fullWidth
					label="Address"
					value={address}
					onChange={(e) => setAddress(e.target.value)}
					margin="normal"
				/>
				<TextField
					fullWidth
					label="Email"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					margin="normal"
				/>
				<TextField
					fullWidth
					label="Phone Number"
					value={phoneNumber}
					onChange={(e) => setPhoneNumber(e.target.value)}
					margin="normal"
				/>
				<TextField
					fullWidth
					label="Contact Person"
					value={contactPerson}
					onChange={(e) => setContactPerson(e.target.value)}
					margin="normal"
				/>
				<Button
					variant="contained"
					color="primary"
					onClick={handleUpdate}
					sx={{ mt: 2 }}
				>
					Update Information
				</Button>
			</Box>
		</Container>
	);
};

export default Account;
