export const MainHostname = 'axwatertown.com';
export const SlaveHostname = 'dash.axwatertown.com';
export const getRedictUrl = (pathname: string) => {
    const { hostname } = window.location;
    if (hostname === MainHostname) {
        return pathname = 'https://' + SlaveHostname + pathname
    } else {
        // return pathname = 'https://' + SlaveHostname + pathname
        return pathname
    }
}