import React, { useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";

import Menu from "@mui/material/Menu";
import logo from "../assets/logo.png";
import PersonIcon from "@mui/icons-material/Person";
import {
	getRedictUrl,
	ParentHostame,
	ChildHostname,
} from "../assets/config.ts";
import { useAuth0 } from "@auth0/auth0-react";

const logoStyle = {
	width: "140px",
	height: "auto",
	cursor: "pointer",
};

const Header: React.FC = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const isDetailPage = location.pathname === "/projects";
	const theme = useTheme();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [currentMenu, setCurrentMenu] = useState<string | null>(null);
	const { loginWithRedirect, isAuthenticated, user, logout } = useAuth0();

	const handleMenuOpen = (
		event: React.MouseEvent<HTMLElement>,
		menuName: string
	) => {
		setAnchorEl(event.currentTarget);
		setCurrentMenu(menuName);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
		setCurrentMenu(null);
	};

	const menuItems: { [key: string]: string[] } = {
		"Generate Report": ["Report 1", "Report 2", "Report 3"],
		"Item 2": ["Subitem 2.1", "Subitem 2.2", "Subitem 2.3"],
		"Item 3": ["Subitem 3.1", "Subitem 3.2", "Subitem 3.3"],
		Product: ["Projects", "Feature 2", "Pricing"],
		Company: ["About Us", "Team", "Careers"],
		Legal: ["Terms of Service", "Privacy Policy", "Cookie Policy"],
		User: ["Account", "Settings", "Logout"],
	};

	const handleProjectClick = () => {
		if (window.location.hostname === ParentHostame) {
			window.location.href = "https://" + ChildHostname + "/project";
		} else {
			if (!isAuthenticated) {
				navigate("/signin");
				// window.location.href = "https://" + ChildHostname + "/signin";
				return;
			}
			navigate(getRedictUrl("/project"));
		}
	};

	const handleUserMenuItemClick = (item: string) => {
		handleMenuClose();
		if (item === "Logout") {
			logout({ logoutParams: { returnTo: "https://" + ParentHostame } });
		}
	};

	return (
		<AppBar
			position="fixed"
			sx={{
				boxShadow: 0,
				bgcolor: "transparent",
				backgroundImage: "none",
				mt: 2,
			}}
		>
			<Container maxWidth="lg">
				<Toolbar
					variant="regular"
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						flexShrink: 0,
						borderRadius: "999px",
						bgcolor: "rgba(255, 255, 255, 0.4)",
						backdropFilter: "blur(24px)",
						maxHeight: 40,
						border: "1px solid",
						borderColor: "divider",
						boxShadow: `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`,
					}}
				>
					<Box
						sx={{
							flexGrow: 1,
							display: "flex",
							alignItems: "center",
							ml: "-18px",
							px: 0,
							marginLeft: "10px",
						}}
					>
						<Link to="/">
							<img src={logo} style={logoStyle} alt="logo" />
						</Link>
						{isDetailPage ? (
							<>
								{["Generate Report", "Item 2", "Item 3"].map(
									(item) => (
										<React.Fragment key={item}>
											<Button
												color="inherit"
												sx={{ color: "text.primary" }}
												onMouseEnter={(event) =>
													handleMenuOpen(event, item)
												}
											>
												{item}
											</Button>
											<Menu
												anchorEl={anchorEl}
												open={currentMenu === item}
												onClose={handleMenuClose}
												MenuListProps={{
													onMouseLeave:
														handleMenuClose,
												}}
											>
												{menuItems[item].map(
													(subItem) => (
														<MenuItem
															key={subItem}
															onClick={
																handleMenuClose
															}
														>
															{subItem}
														</MenuItem>
													)
												)}
											</Menu>
										</React.Fragment>
									)
								)}
							</>
						) : (
							<Box sx={{ display: { xs: "none", md: "flex" } }}>
								{["Product", "Company", "Legal"].map((item) => (
									<React.Fragment key={item}>
										<MenuItem
											sx={{ py: "6px", px: "12px" }}
											onMouseEnter={(event) =>
												handleMenuOpen(event, item)
											}
										>
											<Typography
												variant="body2"
												color="text.primary"
											>
												{item}
											</Typography>
										</MenuItem>
										<Menu
											anchorEl={anchorEl}
											open={currentMenu === item}
											onClose={handleMenuClose}
											MenuListProps={{
												onMouseLeave: handleMenuClose,
											}}
										>
											{menuItems[item].map((subItem) => (
												<MenuItem
													key={subItem}
													onClick={() => {
														window.scrollTo(0, 0);
														handleMenuClose();
														if (
															subItem ===
															"Projects"
														) {
															handleProjectClick();
														}
													}}
												>
													{subItem}
												</MenuItem>
											))}
										</Menu>
									</React.Fragment>
								))}
							</Box>
						)}
					</Box>
					{isDetailPage ? (
						<IconButton
							color="primary"
							sx={{ color: theme.palette.primary.main }}
							onMouseEnter={(event) =>
								handleMenuOpen(event, "User")
							}
						>
							<PersonIcon />
						</IconButton>
					) : (
						<Box
							sx={{
								display: { xs: "none", md: "flex" },
								gap: 0.5,
								alignItems: "center",
							}}
						>
							{isAuthenticated ? (
								<Button
									color="primary"
									variant="contained"
									size="small"
									onMouseEnter={(event) =>
										handleMenuOpen(event, "User")
									}
								>
									{user?.name}
								</Button>
							) : (
								<Button
									color="primary"
									variant="text"
									size="small"
									onClick={() => {
										window.location.href =
											"https://" +
											ChildHostname +
											"/signin?auto=true";
										console.log(loginWithRedirect);
										//  loginWithRedirect()
									}}
								>
									Sign in
								</Button>
							)}
							<Button
								color="primary"
								variant="contained"
								size="small"
								component={Link}
								to="/signup"
							>
								Contact Us
							</Button>
						</Box>
					)}
					<Menu
						anchorEl={anchorEl}
						open={currentMenu === "User"}
						onClose={handleMenuClose}
						MenuListProps={{ onMouseLeave: handleMenuClose }}
					>
						{menuItems["User"].map((subItem) => (
							<MenuItem
								key={subItem}
								onClick={() => handleUserMenuItemClick(subItem)}
							>
								{subItem}
							</MenuItem>
						))}
					</Menu>
				</Toolbar>
			</Container>
		</AppBar>
	);
};

export default Header;
