import React, { useState, useEffect } from 'react';
import { Grid, Paper, Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import DescriptionIcon from '@mui/icons-material/Description';
import ScienceIcon from '@mui/icons-material/Science';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import BarChartIcon from '@mui/icons-material/BarChart';

export interface Pipeline {
  id: string;
  active: number;
  codename: string;
  company: string;
  product_type: string;
  start_date: string;
  color: string;
  index: number;
}

const ProjectStats = (props: { pipelineList: Pipeline[] }) => {
  const theme = useTheme();
  const [stats, setStats] = useState([
    { label: 'Total Projects', value: 0, icon: <DescriptionIcon /> },
    { label: 'In Progress', value: 0, icon: <ScienceIcon /> },
    { label: 'Completed', value: 0, icon: <BarChartIcon /> },
    { label: 'Unique Companies', value: 0, icon: <MedicalServicesIcon /> },
  ]);

  useEffect(() => {
    try {
      const response: Pipeline[] = Array.isArray(props.pipelineList) ? props.pipelineList : [];
      
      const totalProjects = response.length;
      const inProgress = response.filter(pipeline => pipeline.active === 1).length;
      const completed = response.filter(pipeline => pipeline.active === 0).length;
      const uniqueCompanies = new Set(response.map(pipeline => pipeline.company)).size;

        setStats([
          { label: 'Total Projects', value: totalProjects, icon: <DescriptionIcon /> },
          { label: 'In Progress', value: inProgress, icon: <ScienceIcon /> },
          { label: 'Completed', value: completed, icon: <BarChartIcon /> },
          { label: 'Unique Companies', value: uniqueCompanies, icon: <MedicalServicesIcon /> },
        ]);
    } catch (error) {
      console.error("Failed to fetch pipeline data:", error);
    }
  }, [props.pipelineList]);

  return (
    <Box sx={{ mb: 4 }}>
      <Typography variant="h5" sx={{ mb: 2 }}>Pipelines Status</Typography>
      <Grid container spacing={2}>
        {stats.map((stat, index) => (
          <Grid item xs={6} sm={3} key={index}>
            <Paper 
              elevation={2}
              sx={{ 
                p: 2, 
                textAlign: 'left',
                bgcolor: theme.palette.background.default,
                borderRadius: 2,
                transition: '0.3s',
                '&:hover': {
                  transform: 'translateY(-5px)',
                  boxShadow: 3,
                },
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                {React.cloneElement(stat.icon, { color: 'primary', sx: { mr: 1 } })}
                <Typography variant="h4" sx={{ fontWeight: 'bold' }}>{stat.value}</Typography>
              </Box>
              <Typography variant="body2" color="text.secondary">{stat.label}</Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ProjectStats;