import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useAuth0 } from "@auth0/auth0-react";
// import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
// import Link from '@mui/material/Link';
// import Grid from '@mui/material/Grid';
import { useEffect } from "react";

export default function SignIn() {
	const { loginWithRedirect } = useAuth0();
	// Use useEffect to handle automatic redirect
	useEffect(() => {
		if (window.location.search.includes("auto=true")) {
			console.log("signin page - auto redirecting");
			loginWithRedirect();
		}
	}, [loginWithRedirect]);
	// if (window.location.search.includes("auto=true")) {
	// 	console.log("signin page");
	// 	loginWithRedirect();
	// 	return null;
	// }

	// const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
	//   event.preventDefault();
	//   const data = new FormData(event.currentTarget);
	//   console.log({
	//     email: data.get('email'),
	//     password: data.get('password'),
	//   });
	// };

	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<Box
				sx={{
					marginTop: 16,
					marginBottom: 6,
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					textAlign: "center",
				}}
			>
				<Avatar
					sx={{
						m: 2,
						bgcolor: "secondary.main",
						width: 46,
						height: 46,
					}}
				>
					<LockOutlinedIcon />
				</Avatar>
				<Typography
					component="h1"
					variant="h5"
					sx={{
						mb: 3,
						fontWeight: 600,
						color: "text.primary",
					}}
				>
					Access Restricted
				</Typography>

				<Typography
					variant="body1"
					color="text.secondary"
					sx={{
						mb: 4,
						px: 2,
					}}
				>
					You are not authorized to view this page. Please sign in to
					continue accessing our platform.
				</Typography>

				<Button
					fullWidth
					variant="contained"
					color="primary"
					onClick={() => loginWithRedirect()}
					sx={{
						py: 1.5,
						borderRadius: 2,
						textTransform: "none",
						fontSize: "1rem",
						fontWeight: 600,
						boxShadow: "none",
						"&:hover": {
							backgroundColor: "primary.dark",
						},
					}}
				>
					Sign In
				</Button>
				{/* <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
				<TextField
					margin="normal"
					required
					fullWidth
					id="email"
					label="Email Address"
					name="email"
					autoComplete="email"
					autoFocus
				/>
				<TextField
					margin="normal"
					required
					fullWidth
					name="password"
					label="Password"
					type="password"
					id="password"
					autoComplete="current-password"
				/>
				<FormControlLabel
					control={<Checkbox value="remember" color="primary" />}
					label="Remember me"
				/>
				<Button
					type="submit"
					fullWidth
					variant="contained"
					sx={{ mt: 3, mb: 2 }}
				>
					Sign In
				</Button>
				<Grid container>
					<Grid item xs>
						<Link href="#" variant="body2">
							Forgot password?
						</Link>
					</Grid>
					<Grid item>
						<Link href="#" variant="body2">
							{"Don't have an account? Sign Up"}
						</Link>
					</Grid>
				</Grid>
				</Box> */}
			</Box>
		</Container>
	);
}
