import React, { useState, useRef, useEffect } from "react";
import {
	Box,
	TextField,
	IconButton,
	Paper,
	Typography,
	List,
	ListItem,
	Avatar,
	CircularProgress,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import ChatIcon from "@mui/icons-material/Chat";
import CloseIcon from "@mui/icons-material/Close";
import Person2TwoToneIcon from "@mui/icons-material/Person2TwoTone";
import SmartToyOutlinedIcon from "@mui/icons-material/SmartToyOutlined";
import { chatWithBot } from "../services/documentService.ts";

interface Message {
	text: string;
	isUser: boolean;
	isLoading?: boolean;
}

const WELCOME_MESSAGE =
	"Hello! I'm your AI assistant. How can I help you today?";
const WELCOME_DELAY = 500;

const Chatbot: React.FC = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [messages, setMessages] = useState<Message[]>([]);
	const [input, setInput] = useState("");
	const [sessionId, setSessionId] = useState("");
	const [isInitializing, setIsInitializing] = useState(false);
	const messagesEndRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		setSessionId(
			`session_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`
		);
	}, []);

	const showWelcomeMessage = () => {
		setMessages([
			{
				text: "",
				isUser: false,
				isLoading: true,
			},
		]);

		setTimeout(() => {
			setMessages([
				{
					text: WELCOME_MESSAGE,
					isUser: false,
					isLoading: false,
				},
			]);
			setIsInitializing(false);
		}, WELCOME_DELAY);
	};

	const toggleChat = () => {
		const newIsOpen = !isOpen;
		setIsOpen(newIsOpen);

		if (newIsOpen && messages.length === 0 && !isInitializing) {
			setIsInitializing(true);
			showWelcomeMessage();
		}
	};

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setInput(event.target.value);
	};

	const handleSendMessage = async () => {
		if (input.trim()) {
			const userMessage: Message = { text: input, isUser: true };
			setMessages((prevMessages) => [...prevMessages, userMessage]);
			setInput("");
			setMessages((prevMessages) => [
				...prevMessages,
				{ text: "", isUser: false, isLoading: true },
			]);

			try {
				const response = await chatWithBot(sessionId, input);
				const botResponse: Message = {
					text: response,
					isUser: false,
				};
				setMessages((prevMessages) =>
					prevMessages.slice(0, -1).concat(botResponse)
				);
			} catch (error) {
				console.error("Error calling chatbot API:", error);
				const errorMessage: Message = {
					text:
						error instanceof Error
							? error.message
							: "An unknown error occurred.",
					isUser: false,
				};
				setMessages((prevMessages) =>
					prevMessages.slice(0, -1).concat(errorMessage)
				);
			}
		}
	};

	const handleKeyPress = (event: React.KeyboardEvent) => {
		if (event.key === "Enter") {
			handleSendMessage();
		}
	};

	useEffect(() => {
		if (messagesEndRef.current) {
			messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
		}
	}, [messages]);

	return (
		<>
			{!isOpen && (
				<IconButton
					onClick={toggleChat}
					sx={{
						position: "fixed",
						bottom: 16,
						right: 16,
						backgroundColor: "primary.main",
						color: "white",
						"&:hover": {
							color: "primary.main",
						},
					}}
				>
					<ChatIcon />
				</IconButton>
			)}

			{isOpen && (
				<Paper
					elevation={4}
					sx={{
						position: "fixed",
						bottom: 16,
						right: 16,
						width: 300,
						height: 400,
						borderRadius: 3,
						display: "flex",
						flexDirection: "column",
						bgcolor: "rgba(255, 255, 255, 0.4)",
						backdropFilter: "blur(24px)",
						border: "1px solid",
						borderColor: "divider",
						boxShadow: `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`,
					}}
				>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							borderRadius: "12px 12px 0 0",
							padding: 1,
							borderBottom: "1px solid #ddd",
							bgcolor: "primary.main",
							color: "white",
							boxShadow: `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`,
						}}
					>
						<Typography variant="h6">Chatbot</Typography>
						<IconButton onClick={toggleChat}>
							<CloseIcon />
						</IconButton>
					</Box>
					<Box sx={{ flexGrow: 1, padding: 1, overflowY: "auto" }}>
						<List>
							{messages.map((message, index) => (
								<ListItem
									key={index}
									sx={{
										display: "flex",
										justifyContent: message.isUser
											? "flex-end"
											: "flex-start",
										padding: 0,
										marginBottom: 1,
									}}
								>
									{!message.isUser && (
										<Avatar
											sx={{
												marginRight: 1,
												bgcolor: "primary.main",
												width: 30,
												height: 30,
											}}
										>
											<SmartToyOutlinedIcon fontSize="small" />
										</Avatar>
									)}
									<Box
										sx={{
											bgcolor: "grey.200",
											color: "black",
											borderRadius: 2,
											padding: 1,
											maxWidth: "70%",
											display: "inline-block",
											textAlign: message.isUser
												? "right"
												: "left",
										}}
									>
										{message.isLoading ? (
											<CircularProgress size={10} />
										) : (
											message.text
										)}
									</Box>
									{message.isUser && (
										<Avatar
											sx={{
												marginLeft: 1,
												bgcolor: "primary.main",
												width: 30,
												height: 30,
											}}
										>
											<Person2TwoToneIcon fontSize="small" />
										</Avatar>
									)}
								</ListItem>
							))}
							<div ref={messagesEndRef} />
						</List>
					</Box>
					<Box
						sx={{
							display: "flex",
							padding: 1,
							borderTop: "1px solid #ddd",
						}}
					>
						<TextField
							fullWidth
							value={input}
							onChange={handleInputChange}
							onKeyPress={handleKeyPress}
							placeholder="Type a message..."
							variant="outlined"
							size="small"
						/>
						<IconButton
							color="primary"
							onClick={handleSendMessage}
							sx={{ marginLeft: 1 }}
						>
							<SendIcon />
						</IconButton>
					</Box>
				</Paper>
			)}
		</>
	);
};

export default Chatbot;
