import ReactDOM from "react-dom/client";
import { Auth0Provider } from "@auth0/auth0-react";
import App from "./App.tsx";
import "./index.css";
import { ChildHostname } from "./assets/config.ts";

ReactDOM.createRoot(document.getElementById("root")!).render(
	<Auth0Provider
		domain="dev-lrwv3p8rjsm8nb60.us.auth0.com"
		clientId="gPxSB8BSARTMyCcJrxv3OLtcRhHTYRnm"
		authorizationParams={{
			redirect_uri:
				window.location.hostname === "localhost"
					? window.location.origin + "/project"
					: "https://" + ChildHostname + "/project",
		}}
		useRefreshTokens={true}
		cacheLocation="localstorage"
	>
		<App />
	</Auth0Provider>
);
