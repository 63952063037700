import { createTheme, ThemeOptions, alpha } from '@mui/material/styles';
import { PaletteMode } from '@mui/material';

declare module '@mui/material/styles' {
	interface TypographyVariants {
		customH5: React.CSSProperties;
	}

	interface TypographyVariantsOptions {
		customH5?: React.CSSProperties;
	}
}

declare module '@mui/material/Typography' {
	interface TypographyPropsVariantOverrides {
		customH5: true;
	}
}

// Custom color definitions
const brand = {
	50: '#F0F7FF',
	100: '#CEE5FD',
	200: '#9CCCFC',
	300: '#55A6F6',
	400: '#0A66C2',
	500: '#0959AA',
	600: '#064079',
	700: '#033363',
	800: '#02294F',
	900: '#021F3B',
};

const gray = {
	50: '#FBFCFE',
	100: '#EAF0F5',
	200: '#D6E2EB',
	300: '#BFCCD9',
	400: '#94A6B8',
	500: '#5B6B7C',
	600: '#4C5967',
	700: '#364049',
	800: '#131B20',
	900: '#090E10',
};

const green = {
	50: '#F6FEF6',
	100: '#E3FBE3',
	200: '#C7F7C7',
	300: '#A1E8A1',
	400: '#51BC51',
	500: '#1F7A1F',
	600: '#136C13',
	700: '#0A470A',
	800: '#042F04',
	900: '#021D02',
};

const getDesignTokens = (mode: PaletteMode): ThemeOptions => ({
	palette: {
		mode,
		primary: {
			light: brand[200],
			main: brand[500],
			dark: brand[800],
			contrastText: brand[50],
		},
		secondary: {
			light: brand[300],
			main: brand[500],
			dark: brand[800],
		},
		warning: {
			main: '#F7B538',
			dark: '#F79F00',
		},
		error: {
			light: '#FFA48D',
			main: '#FF4842',
			dark: '#B72136',
		},
		success: {
			light: green[300],
			main: green[400],
			dark: green[800],
		},
		grey: gray,
		divider: alpha(gray[300], 0.5),
		background: {
			default: '#fff',
			paper: gray[50],
		},
		text: {
			primary: gray[800],
			secondary: gray[600],
		},
		action: {
			selected: alpha(brand[200], 0.2),
		},
	},
	typography: {
		fontFamily: ['"Inter", "sans-serif"'].join(','),
		h1: {
			fontSize: 60,
			fontWeight: 600,
			lineHeight: 78 / 70,
			letterSpacing: -0.2,
		},
		h2: {
			fontSize: 48,
			fontWeight: 600,
			lineHeight: 1.2,
		},
		h3: {
			fontSize: 42,
			lineHeight: 1.2,
		},
		h4: {
			fontSize: 36,
			fontWeight: 500,
			lineHeight: 1.5,
		},
		h5: {
			fontSize: 20,
			fontWeight: 600,
		},
		h6: {
			fontSize: 18,
		},
		subtitle1: {
			fontSize: 18,
		},
		subtitle2: {
			fontSize: 16,
		},
		body1: {
			fontWeight: 400,
			fontSize: 15,
		},
		body2: {
			fontWeight: 400,
			fontSize: 14,
		},
		caption: {
			fontWeight: 400,
			fontSize: 12,
		},
		customH5: {
			fontSize: '18px',
			fontWeight: 600,
		},
	},
	components: {
		MuiAccordion: {
			defaultProps: {
				elevation: 0,
				disableGutters: true,
			},
			styleOverrides: {
				root: {
					padding: 8,
					overflow: 'clip',
					backgroundColor: '#fff',
					border: '1px solid',
					borderColor: gray[100],
					':before': {
						backgroundColor: 'transparent',
					},
					'&:first-of-type': {
						borderTopLeftRadius: 10,
						borderTopRightRadius: 10,
					},
					'&:last-of-type': {
						borderBottomLeftRadius: 10,
						borderBottomRightRadius: 10,
					},
				},
			},
		},
		MuiAccordionSummary: {
			styleOverrides: {
				root: {
					border: 'none',
					borderRadius: 8,
					'&:hover': { backgroundColor: gray[100] },
				},
			},
		},
		MuiAccordionDetails: {
			styleOverrides: {
				root: { mb: 20, border: 'none' },
			},
		},
		MuiToggleButtonGroup: {
			styleOverrides: {
				root: {
					borderRadius: '10px',
					boxShadow: `0 4px 16px ${alpha(gray[400], 0.2)}`,
					'& .Mui-selected': {
						color: brand[500],
					},
				},
			},
		},
		MuiToggleButton: {
			styleOverrides: {
				root: {
					padding: '12px 16px',
					textTransform: 'none',
					borderRadius: '10px',
					fontWeight: 500,
				},
			},
		},
		MuiButtonBase: {
			defaultProps: {
				disableTouchRipple: true,
				disableRipple: true,
			},
			styleOverrides: {
				root: {
					boxSizing: 'border-box',
					transition: 'all 100ms ease-in',
					'&:focus-visible': {
						outline: `3px solid ${alpha(brand[500], 0.5)}`,
						outlineOffset: '2px',
					},
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: ({ ownerState }) => ({
					boxSizing: 'border-box',
					boxShadow: 'none',
					borderRadius: '10px',
					textTransform: 'none',
					'&:active': {
						transform: 'scale(0.98)',
					},
					...(ownerState.size === 'small' && {
						maxHeight: '32px',
					}),
					...(ownerState.size === 'medium' && {
						height: '40px',
					}),
					...(ownerState.variant === 'contained' &&
						ownerState.color === 'primary' && {
						color: brand[50],
						background: brand[500],
						backgroundImage: `linear-gradient(to bottom, ${brand[400]}, ${brand[600]})`,
						boxShadow: `inset 0 1px ${alpha(brand[300], 0.4)}`,
						outline: `1px solid ${brand[700]}`,
						'&:hover': {
							background: brand[400],
							backgroundImage: 'none',
							boxShadow: `0 0 0 1px  ${alpha(brand[300], 0.5)}`,
						},
					}),
					...(ownerState.variant === 'outlined' && {
						backgroundColor: alpha(brand[300], 0.1),
						borderColor: brand[300],
						color: brand[500],
						'&:hover': {
							backgroundColor: alpha(brand[300], 0.3),
							borderColor: brand[200],
						},
					}),
					...(ownerState.variant === 'text' && {
						color: brand[500],
						'&:hover': {
							backgroundColor: alpha(brand[300], 0.3),
							borderColor: brand[200],
						},
					}),
				}),
			},
		},
		MuiCard: {
			styleOverrides: {
				root: ({ ownerState }) => ({
					backgroundColor: gray[50],
					borderRadius: 10,
					border: `1px solid ${alpha(gray[200], 0.8)}`,
					boxShadow: 'none',
					transition: 'background-color, border, 80ms ease',
					...(ownerState.variant === 'outlined' && {
						background: `linear-gradient(to bottom, #FFF, ${gray[50]})`,
						'&:hover': {
							borderColor: brand[300],
							boxShadow: `0 0 24px ${brand[100]}`,
						},
					}),
				}),
			},
		},
		MuiChip: {
			styleOverrides: {
				root: {
					alignSelf: 'center',
					py: 1.5,
					px: 0.5,
					background: `linear-gradient(to bottom right, ${brand[50]}, ${brand[100]})`,
					border: '1px solid',
					borderColor: `${alpha(brand[500], 0.3)}`,
					fontWeight: '600',
					'&:hover': {
						backgroundColor: brand[500],
					},
					'&:focus-visible': {
						borderColor: brand[800],
						backgroundColor: brand[200],
					},
					'& .MuiChip-label': {
						color: brand[500],
					},
					'& .MuiChip-icon': {
						color: brand[500],
					},
				},
			},
		},
		MuiDivider: {
			styleOverrides: {
				root: {
					borderColor: `${alpha(gray[200], 0.8)}`,
				},
			},
		},
		MuiLink: {
			defaultProps: {
				underline: 'none',
			},
			styleOverrides: {
				root: {
					color: brand[600],
					fontWeight: 500,
					position: 'relative',
					textDecoration: 'none',
					'&::before': {
						content: '""',
						position: 'absolute',
						width: 0,
						height: '1px',
						bottom: 0,
						left: 0,
						backgroundColor: brand[200],
						opacity: 0.7,
						transition: 'width 0.3s ease, opacity 0.3s ease',
					},
					'&:hover::before': {
						width: '100%',
						opacity: 1,
					},
				},
			},
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					borderRadius: '99px',
					color: gray[500],
					fontWeight: 500,
				},
			},
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					backgroundImage: 'none',
					backgroundColor: gray[100],
				},
			},
		},
		MuiSwitch: {
			styleOverrides: {
				root: {
					boxSizing: 'border-box',
					width: 36,
					height: 24,
					padding: 0,
					transition: 'background-color 100ms ease-in',
					'&:hover': {
						'& .MuiSwitch-track': {
							backgroundColor: brand[600],
						},
					},
					'& .MuiSwitch-switchBase': {
						'&.Mui-checked': {
							transform: 'translateX(13px)',
						},
					},
					'& .MuiSwitch-track': {
						borderRadius: 50,
					},
					'& .MuiSwitch-thumb': {
						boxShadow: '0 0 2px 2px rgba(0, 0, 0, 0.2)',
						backgroundColor: '#FFF',
						width: 16,
						height: 16,
						margin: 2,
					},
				},
				switchBase: {
					height: 24,
					width: 24,
					padding: 0,
					color: '#fff',
					'&.Mui-checked + .MuiSwitch-track': {
						opacity: 1,
					},
				},
			},
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					'& label .Mui-focused': {
						color: 'white',
					},
					'& .MuiInputBase-input': {
						boxSizing: 'border-box',
						'&::placeholder': {
							opacity: 0.7,
						},
					},
					'& .MuiOutlinedInput-root': {
						boxSizing: 'border-box',
						minWidth: 280,
						minHeight: 40,
						height: '100%',
						borderRadius: '10px',
						border: '1px solid',
						borderColor: gray[200],
						transition: 'border-color 120ms ease-in',
						'& fieldset': {
							border: 'none',
							boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
							background: `${alpha('#FFF', 0.3)}`,
						},
						'&:hover': {
							borderColor: brand[300],
						},
						'&.Mui-focused': {
							borderColor: brand[400],
							outline: '4px solid',
							outlineColor: brand[200],
						},
					},
				},
			},
		},
	},
});

const theme = createTheme(getDesignTokens('light'));

export default theme;