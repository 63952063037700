import { lazy } from "react";
import {
	BrowserRouter as Router,
	Route,
	Routes,
	useLocation,
	useNavigate,
	Navigate,
} from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { useAuth0 } from "@auth0/auth0-react";
import theme from "./theme";
import Header from "./components/Header";
// import Footer from "./components/Footer";
import Chatbot from "./components/Chatbot";
import { ReactNode, useEffect } from "react";
import { ParentHostame, ChildHostname } from "./assets/config.ts";
const Home = lazy(() => import("./pages/Home"));
const SignIn = lazy(() => import("./pages/SignIn"));
const Signup = lazy(() => import("./pages/Signup"));
const Account = lazy(() => import("./pages/Account"));
const ProjectsOverview = lazy(() => import("./pages/ProjectsOverview"));
const ProjectDetail = lazy(() => import("./pages/ProjectDetail"));
const DocumentDetail = lazy(() => import("./pages/DocumentDetail"));

const ProtectedRoute = ({ children }: { children: ReactNode }) => {
	const { isAuthenticated, isLoading } = useAuth0();
	const location = useLocation();

	if (isLoading) {
		return <div>Loading...</div>;
	}

	if (!isAuthenticated) {
		return <Navigate to="/signin" state={{ from: location }} replace />;
	}

	return <>{children}</>;
};

const RouteMonitor = () => {
	const routerLocation = useLocation();
	const navigate = useNavigate();
	const { isAuthenticated, isLoading } = useAuth0();

	useEffect(() => {
		const { pathname } = routerLocation;
		const hostname = window.location.hostname;
		const protectedPaths = ["/project", "/document"];
		const isProtectedPath = protectedPaths.some((path) =>
			pathname.startsWith(path)
		);

		switch (hostname) {
			case ParentHostame:
			case ChildHostname:
				if (isProtectedPath) {
					if (!isAuthenticated) {
						navigate("/signin");
					}
				}
				break;
			case "localhost":
				console.log(
					`Auth state - isAuthenticated: ${isAuthenticated}, isLoading: ${isLoading}, Path: ${pathname}`
				);
				if (isProtectedPath) {
					if (!isAuthenticated) {
						navigate("/signin");
					}
				}
				break;
		}
	}, [isAuthenticated, isLoading, navigate, routerLocation]);
	return null;
};

const Layout = ({ children }: { children: ReactNode }) => {
	const location = useLocation();

	const showChatbot =
		["/project", "/project/"].includes(location.pathname) ||
		location.pathname.startsWith("/project/") ||
		location.pathname.startsWith("/document/");

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				minHeight: "100vh",
				position: "relative",
			}}
		>
			<Header />
			<Box sx={{ flex: 1 }}>{children}</Box>
			{showChatbot && (
				<Box
					sx={{
						position: "fixed",
						bottom: "60px",
						right: "20px",
						zIndex: 1000,
					}}
				>
					<Chatbot />
				</Box>
			)}
			{/* <Footer /> */}
		</Box>
	);
};

function App() {
	const { isLoading } = useAuth0();

	if (isLoading) {
		return <div>Loading...</div>;
	}

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<Router>
				<RouteMonitor />
				<Layout>
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/signin" element={<SignIn />} />
						<Route path="/signup" element={<Signup />} />
						<Route
							path="/account"
							element={
								<ProtectedRoute>
									<Account />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/project"
							element={
								<ProtectedRoute>
									<ProjectsOverview />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/project/:projectId"
							element={
								<ProtectedRoute>
									<ProjectDetail />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/document/:documentId"
							element={
								<ProtectedRoute>
									<DocumentDetail />
								</ProtectedRoute>
							}
						/>
					</Routes>
				</Layout>
			</Router>
		</ThemeProvider>
	);
}

export default App;
